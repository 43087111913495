.SaibaMaisButton{

    display: flex;
    align-items: center;
    justify-content: center;

    width: 9.6875rem;
    height: 3rem;

    background: #F7E7CE;
    border-radius: 3.125rem;

    border: none;
    color: #473527;
    font-style: normal;
    line-height: normal;
    font-size: 1.0625rem;
    font-weight: lighter;
    text-decoration: none;
    font-family: "Hero Regular";
}

.TratamentoContainer{
    gap: 20px;
    width: 85%;
    margin: auto;
    max-width: 1280px;

    display: flex;
    align-items: center;
    padding-top: 45px;
    padding-bottom: 25px;
    justify-content: space-between;
}

.AddPromocao{
    border: none;
    padding: 12px;
    cursor: pointer;

    color: white;
    border-radius: 20px;

    gap: 10px;
    display: flex;
    align-items: center;

    font-size: 15px;
    font-weight: bold;
    background-color: #0AB165;
    font-family: "Hero Regular";

    svg{
        width: 20px;
        height: 20px;
    }
}

.SaibaMaisLinkContainer{
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 40px;
    justify-content: center;
}

@media all and ( max-width: 1000px ){
    .TratamentoContainer{
        width: 90%;
        justify-content: center;
    }
}

@media all and ( max-width: 800px ){
    .TratamentoContainer{
        width: 100%;
        
        display: flex;
        flex-direction: column;
    }
}
