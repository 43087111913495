@use "../../assets/Fonts/index";
@import "../../assets/Colors/Colors";


.TratamentoContainer{
    gap: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    
    width: 22rem;

    span{
        width: 100% !important;
    }

    .ImageProps{
        
        width: 100%;
        height: 24rem;

        border-radius: 20px;
        object-fit: cover;
        border: 1px solid lightgray;
    }

    .ImageDescribeContainer{
        width: 100%;

        gap: 1rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        font-family: "Hero Regular";

        .titleStyles{
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: $primary-dark;
        }

        .descriptionStyles{
            text-align: center;
            font-style: normal;
            font-weight: 400;
            line-height: 1.25rem;
            font-size: 1.0625rem;
            color: $primary-dark;
        }

        .LinkStyles{
            text-align: center;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            font-size: 1.0625rem;
            color: $primary-light;
            text-decoration-line: underline;
        }
    }
}

@media all and (max-width: 400px){
    .TratamentoContainer{
        width: 90%;
    }
}