@import '../../assets/Colors/Colors.scss';

.Map{
    width: 100%;
    height: 55vh;
    position: relative;
    
    display: flex;
    align-items: flex-end;
    justify-content: center;
    
    background-size: cover;
    background-position: center;
    background-blend-mode: soft-light;
    background-color: rgba(0, 0, 0, 0.226);
    background-image: url("../../assets/LogosPngs/mapSectionImg.png");
    
    a{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        width: 230px;
        padding: 0 20px;
        margin-bottom: 35px;
        height: 50px;
        border: none;
        border-radius: 10px;
        background-color: $primary-dark;
        text-decoration: none;
        color: #fff;

        span{
            margin-left: 45px;
            font-family: 'Hero Regular';
            font-weight: 600;
        }
    }
}