

.Container{
    
    width: 100%;
    
    .Content{

        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        
        padding-top: 50px;
        padding-bottom: 50px;
        max-width: 1200px;
        margin: auto;

        .MobileHeader{
            display: none;
        }

        span > .Picture{
            width: 100%;
            height: 100%;
            min-height: 50rem;

            border-radius: 0rem 2.5rem 2.5rem 0rem;
            // background: lightgray 45% 0% / cover no-repeat;
            object-fit: cover;
        }

        .SecondColumn{
            display: flex;
            width: 30%;
            gap: 0.625rem;
            flex-shrink: 0;
            flex-direction: column;
            align-items: flex-start;
            padding: 0.625rem 2.5rem;
    
            .FirstText{

                :nth-child(1), :nth-child(2), :nth-child(3){
                    font-weight: 400;
                    font-style: normal;
                    line-height: normal;
                }

                :nth-child(1), :nth-child(2){
                    font-family: "Agne Regular";
                }
                
                :nth-child(1){
                    color: #C17676;
                    font-size: 2.3875rem;
                }
    
                :nth-child(2){
                    color: #473527;
                    font-size: 3.0375rem;
                }
    
                :nth-child(3){
                    color: #473527;
                    font-size: 1.15rem;
                    font-family: "Hero Regular";
                    line-height: 1.8875rem; /* 135% */
                }
            }
    
            .SecondText{
    
                gap: 10px;
                display: flex;
                flex-direction: column;
    
                color: #473527;
                font-weight: 400;
                font-style: normal;
                font-size: 1.15rem;
                font-family: "Hero Regular";
                line-height: 1.8875rem;
    
                :nth-child(1){
                    color: #473527;
                    font-family: "Hero Regular" Light Bold;
                    font-size: 1.25rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 1.6875rem;
                }
    
    
            }
        }
    }
}

@media all and (max-width: 1280px){
    .Container{

        .Content{

            .Picture{
                width: 50%;
                background: lightgray 45% 0% / cover no-repeat;
            }

            .SecondColumn{

                .FirstText{
                    :nth-child(1){
                        font-size: 2rem;
                    }
                    :nth-child(2){
                        font-size: 2.5rem;
                    }
                    :nth-child(3){
                        font-size: 1rem;
                    }
                }
                .SecondText{
                    font-size: 1rem;
                    
                    :nth-child(1){
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}

@media all and (max-width: 800px){
    .Container{

        display: flex;
        justify-content: center;

        .Content{

            width: 80%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 20px;

            .MobileHeader{
                display: flex;
                flex-direction: column;

                :nth-child(1), :nth-child(2), :nth-child(3){
                    font-weight: 400;
                    font-style: normal;
                    line-height: normal;
                    font-family: "Agne Regular";
                }

                :nth-child(1){
                    color: #C17676;
                    font-size: 2.3875rem;
                }
    
                :nth-child(2), :nth-child(3){
                    color: #473527;
                    font-size: 3.5375rem;
                    white-space: normal;
                }
            }

            .Picture{
                width: 100%;
                height: 30rem;

                border-radius: 0rem 2.5rem 0rem 2.5rem;
                background: rgb(255, 255, 255) 20% 25% / cover no-repeat;
            }

            .SecondColumn > .FirstText {
                
                :nth-child(1), :nth-child(2){
                    display: none;
                }
                :nth-child(3){
                    width: 100%;
                    text-align: justify;
                }
            }

            .SecondColumn{
                width: 100%;
                padding: 0px;

                :nth-child(2){
                    text-align: justify;
                }
            }
        }
    }
}

@media all and ( max-width: 470px ) {
    .Container > .Content > .MobileHeader {

        :nth-child(1){
            font-size: 2rem;
        }

        :nth-child(2), :nth-child(3){
            font-size: 2.5rem;
        }
    }
}