@use "../../assets/Fonts/index";
@import "../../assets/Colors/Colors";

/* WEB */
.Container {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;

    width: 100%;
    padding: 2em 0;
    background-color: $secondary-light-1;
    color: $primary-dark;

    font-family: "Hero Regular";

    .InstagramMobile {
        display: none;
    }

    .Logo {
        svg {
            width: 10.938rem;
            height: 11.375rem;
        }
        padding-top: 14px;
    }

    .List {
        min-height: 210px;

        .ListTitle {
            font-weight: 900;
            padding-bottom: 10px;
        }

        .ListTitle::after {
            content: '';
            display: block;
            width: 100%;
            height: 0.05rem;
            background-color: $primary-dark;
        }

        .FuncElement {
            display: flex;
            align-items: center;
            
            .FuncText{
                padding-right: 90px;
            }

            span {
              padding-left: 9px;
            }
        }

        ul {
            list-style-type: none;

            li {
                padding: 7px;

                a {
                    text-decoration: none;
                    color: $primary-dark;
                }
            }
        }
    }
}

/* TABLET */
@media (min-width: 800px) and (max-width: 1000px) {

}

/* MOBILE */
@media (max-width: 800px) {
    .Container {
        flex-direction: column;
        align-items: center;
        
        width: 100%;
        padding-top: 2em;
        padding-left: 0;
        padding-bottom: 0;
        padding-right: 0;

        .InstagramMobile {
            display: block;
            padding-top: 1.5em;
        }

        .List {
            min-width: clamp(25%, 100%, 354px);

            ul {
                padding: 0;
            }
        }
    }
}
