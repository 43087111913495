.Container{
    width: 100%;
    display: flex;
    flex-direction: column;

    .NavBarLogin{
        display: flex;
        align-items: center;
        background: #F7E7CE;
        justify-content: center;
        padding: 10px;

        .MainLogo{
            background: 50% / contain no-repeat;
        }

        svg{
            height: 75px;
        }
    }

    .ContentContainer{
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        background-color: #473527;

        .Content{
            gap: 20px;
            width: 30%;
            height: 50vh;
            margin-top: 20px;
            min-height: 500px;
            padding: 20px 50px;
            border-radius: 20px;
            
            display: flex;
            flex-direction: column;
            justify-content: center;
            background-color: #F7E7CE;

            
            .ContentHeader{
                
                gap: 0.75rem;
                display: flex;
                flex-direction: column;
                
                color: #473527;
                font-weight: 500;
                font-style: normal;
                line-height: normal;
                font-family: "Agnes";
                
                .VoltarSite{
                    width: 100%;

                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
        
                    font-weight: 400;
                    color: #473527;
                    font-style: normal;
                    line-height: normal;
                    font-size: 0.8375rem;
                    font-family: "Hero Regular";
                    
                    svg{
                        transform: rotate(180deg);
                    }
                }
                .MainHeader{
                    font-size: 2rem;
                }

                .SubtitleHeader{
                    font-size: 1rem;
                }
            }
            
            .InputsSection{
                gap: 1rem;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .InputContainer{
                    gap: 5px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    .InputElementIcon{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;

                        input{
                            width: 100%;
                            height: 5vh;
                            border: none;
                            font-size: 1rem;
                            max-height: 40px;
                            color: #473527;
                            border-radius: 5px;
                            align-items: center;
                            padding: 0.5rem 1.5rem;
                            outline: none;
                        }

                        input:invalid{
                            border: tomato 1px solid;
                        }
    
                        input:hover{
                            background-color: #f5f5f5;
                        }

                        svg{
                            cursor: pointer;
                            position: absolute;
                            margin-right: 10px;
                        }
                    }

                    div{
                        color: #473527;
                        font-family: "Hero Regular";
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }

                }

                .ErrorMessage{
                    width: 100%;
                    height: 60px;

                    gap: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

                    span{
                        width: 80%;
                        color: white;
                        font-size: medium;
                        font-weight: bold;
                        text-align: center;
                        font-family: system-ui;
                    }

                    
                    padding: 5px;
                    border-radius: 5px;
                    background-color: #C02222;

                    .ErrorSvg{

                        svg{
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            }
        }

        .SendButton{
            cursor: pointer;
            width: 100%;
            height: 2rem;
            border: none;
            
            display: flex;
            flex-direction: column;
            justify-content: center;
            
            height: 10vh;
            max-height: 60px;
            align-items: center;
            background-color: #473527;
            border-radius: 10px;

            color: #FFF;
            text-align: center;
            font-size: 1.5rem;
            font-weight: 700;
            font-family: "Agnes"; // alterar
        }
        .SendButton:hover{
            background-color: #75695f;
        }

        .SendButton:disabled{
            cursor: default;
            background-color: #75695f;
        }
    }
}


@media all and (max-width: 1024px){
    .Container > .ContentContainer > .Content{
        width: 50%;
        padding: 0px 20px;
        border-radius: 10px;
    }
}

@media all and (max-width: 800px){
    .Container > .ContentContainer > .Content{
        width: 80%;
        padding: 20px 20px;
        border-radius: 10px;
    }
}

@media all and (max-width: 600px){
    .Container > .ContentContainer > .Content{
        .InputsSection{
            .ErrorMessage{
                gap: 0px;
                display: flex;
                padding: 10px 0px;
                flex-direction: column;

                span{
                    width: 100%;
                }

                .ErrorSvg > svg{
                    width: 25px;
                    height: 25px;
                }

                height: 80px;
            }
        }
    }
}


@media all and (max-width: 350px){
    .Container > .ContentContainer > .Content > .ContentHeader{
        
        .SubtitleHeader{
            font-size: 0.8rem;
        }
    }
}