@use "../../assets/Fonts/index";
@import "../../assets/Colors/Colors";

.MainContainer {
    min-height: 20rem;
    min-width: 100%;

    gap: 75px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-position: center;
    justify-content: space-evenly;

    .MainTitle {
        font-size: 2em;
        z-index: 30;
        text-align: center;
        font-family: "Hero Regular";
        
        span {
            font-family: "Agne Regular";
        }
    }

    .MainPicture {
        width: 100%;
        opacity: 75%;
        min-height: 20rem;
        position: absolute;
        background-size: contain;
        background-position-y: 50%;
    }

    a {
        text-decoration: none;
    }
}

.WhatsappContainer {
    width: 320px;
    height: 50px;
    background-color: transparent;
    
    border: none;
    border-radius: 10px;

    text-decoration-style: none;
    font-family: "Hero Light";
    font-weight: 900;
    font-size: 17px;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;

    .WhatsappContainerDiv{
        background-color: $primary-whatsapp;
        z-index: 30;
        padding: 12.3px;
        
        border: none;
        border-radius: 10px;

        text-decoration-style: none;
        font-family: "Hero Light";
        font-weight: 900;
        font-size: 17px;
        color: white;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    cursor: pointer;

    .Title {
        display: inline;
        margin-right: 10px;
    }

    .Logo {
        display: inline;

        .SvgLogo {
            width: 28px;
            height: 28px;
        }
    }
}

@media (max-width: 800px) {
    .WhatsappContainer {
        display: none; 
    }

    .MainContainer {
        min-height: calc(29rem - 40px);
        min-width: calc(100% - 200px);
        padding: 20px 20px;

        .MainTitle {
            font-size: 2em;
            margin-top: -200px;
        }

        .MainPicture {
            min-height: 29rem;
        }

        a {
            display: none;
        }
    }
}