@use "../../assets/Fonts/index";
@import "../../assets/Colors/Colors";

.MainContainer{
    min-height: 20rem;
    min-width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 75px;
    background-color: #fff;//$secondary-light-2;
    color: $primary-dark;

    font-size: 2em;
    text-align: center;
    font-family: "Hero Regular";
}