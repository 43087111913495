.ToastContainer{
    
    margin-top: 20px;
    margin-right: 20px;

    position: fixed;
    inset-inline: 0;
    z-index: 20102;
    inset-block-start: 0;
    padding-block-end: 5vh;


    gap: 1vh;
    display: grid;
    justify-content: end;
    justify-items: end;
    pointer-events: none;

    .ToastItemShow{

        padding: 15px;
        border-radius: 10px;

        gap: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        
        font-weight: bold;
        font-family: "Hero Regular";
        animation: toastSlideIn 0.5s ease-in;

        &:hover{
            cursor: pointer;
            box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.25);
        }
    }


    @keyframes toastSlideIn {
        from {
            transform: translateX(100%);
        }
        to {
            transform: translateX(0);
        }
    }
}