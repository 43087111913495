.Container{

    gap: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    padding: 20px 0px 50px 0px;

    .Header{        
        display: inherit;
        align-items: inherit;
        flex-direction: column;
        justify-content: inherit;

        .TitleHeader{
            color: #C17676;
            font-size: 58px;
            font-style: normal;
            line-height: normal;
            font-family: "Agne Regular";
        }

        .SubTitleHeader{
            color: #473527;
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            font-family: "Hero Regular";
        }
    }

    .Content{
        gap: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        .FirstColumn, .LastColumn{
            gap: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            .DescriptionContainer{
                width: 290px;

                gap: 10px;
                display: flex;
                flex-direction: column;

                font-size: 17px;
                font-weight: 400;
                color: #473527;
                font-style: normal;
                line-height: 22px;
                font-family: "Hero Regular";
            }

            .TitleInfo{
                font-weight: 700;
            }
        }

        .FirstColumn > .DescriptionContainer{
            text-align: right;
        }
        .LastColumn > .DescriptionContainer{
            text-align: left;
        }

        span > img{
            width: 350px;
            height: 400px;
            flex-shrink: 0;
            border-radius: 200px;
            box-sizing: border-box;
            object-fit: cover;
            object-position: center top;
        }
    }

    .Button{

        width: 240px;
        height: 31px;
        display: flex;
        padding: 10px 13px;
        align-items: center;
        justify-content: center;
        
        gap: 10px;
        text-decoration: none;
        border-radius: 500px;
        background: #F7E7CE;

        color: #473527;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-family: "Hero Regular";
    }
}

@media all and ( max-width: 1080px ){
    .Container > .Content >.LastColumn{ 
        .DescriptionContainer{
            width: 230px;
        }
    }
    .Container > .Content > .FirstColumn{
        .DescriptionContainer{
            width: 230px;
        }
    }
}

@media all and ( max-width: 1000px ){
    .Container > .Content> span{
        order: -1;
    }
    .Container > .Content > span > img{
        width: 22rem;
        height: 400px;
        border-radius: 20px;
    }

    .Container{
        gap: 50px;
        padding: 0px 50px 50px 50px;

        .Header{
            gap: 10px;
        }

        .Header > .TitleHeader{
            width: 22rem;
            font-size: 2rem;
            text-align: center;
        }

        .Header > .SubTitleHeader{
            font-size: 20px;
        }

        .Content{
            display: flex;
            flex-direction: column;

            .FirstColumn, .LastColumn{
                gap: 20px;
                height: initial;
                justify-content: initial;

                .DescriptionContainer{
                    width: 100%;
                    font-size: 1em;
                    text-align: left;
                }
            }
        }
    }
}