
.Container{

    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 1280px;

    gap: 2em;
    padding: 40px 20px 40px 50px;

    .FirstColumn{
        gap: 1em;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .Ideia{
            display: block;
            color: #473527;
            font-size: 1.25rem;
            font-style: normal;
            line-height: normal;
            font-weight: lighter;
            font-family: "Hero Regular";
        }

        .MainTitle{
            display: block;
            color: #C69874;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            font-size: 2.1875rem;
            font-family: "Agne Regular";
        }

        .MainText{
            display: block;
            height: 9.3125rem;

            overflow: hidden; /* ou overflow: auto; */
            color: #473527;
            font-weight: 400;
            font-style: normal;
            font-size: 1.125rem;
            line-height: 1.5625rem;
            font-family: "Hero Regular";
        }

        .SaibaMaisButton{

            display: flex;
            align-items: center;
            justify-content: center;

            width: 9.6875rem;
            height: 3.9375rem;

            background: #F7E7CE;
            border-radius: 3.125rem;

            border: none;
            color: #473527;
            font-style: normal;
            line-height: normal;
            font-size: 1.0625rem;
            font-weight: lighter;
            text-decoration: none;
            font-family: "Hero Regular";
        }
    }

    .LastColumn{
        width: 50%;
        flex-shrink: 0;
        width: 39.75rem;
        height: 22.25rem;
    
        gap: 30px;
        display: flex;
        flex-direction: row;

        .ColumnImage{
            flex-shrink: 0;
            width: 45%;
            height: 22.3125rem;
            background: lightgray 50% 0%/ cover no-repeat;

        }

        .ColumnImage:nth-child(even){
            border-top-right-radius: 50px;
        }

        .ColumnImage:nth-child(odd){
            border-bottom-left-radius: 50px;
        }
    }
}

@media all and ( max-width: 1330px ){
    .Container{

        .FirstColumn > .MainTitle{
            font-size: 2rem;
        }

        .FirstColumn > .MainText{
            font-size: 1rem;
        }
    }
}


@media all and ( max-width: 1130px ){
    .Container{

        gap: 1.5em;
        margin: auto;

        .FirstColumn{
            width: 50%;

            .MainTitle{
                font-size: 2rem;
            }

            .MainText{
                font-size: 1rem;
                text-align: justify;
            }

            .SaibaMaisButton{
                height: 3.0375rem;
            }
        }

        .LastColumn{
            gap: 10px;
            width: 50%;

            .ColumnImage{
                width: 45%;
                height: 22.3125rem;
            }
        }
    }
}

@media all and ( max-width: 800px ){
    // aqui ja tem que da um display none na maioria das divs
    .Container{
        flex-direction: column;
        padding: 70px 70px 20px 70px;

        .FirstColumn{
            width: 100%;
            flex-direction: column;
            align-items: center;

            .MainTitle{
                max-width: 41rem;
            }
        }

        .LastColumn{
            display: none;
        }

        .ContainerMobAbouts{
            display: flex;
            flex-direction: row;
            gap: 5em;
        }
    }
}

@media all and ( max-width: 655px ){
    .Container{
        
        gap: 2em;
        padding: 40px;

        .FirstColumn > .MainText{
            height: auto;
        }
        .ContainerMobAbouts{
            flex-direction: column;
            gap: 2em;
        }
    }
}
