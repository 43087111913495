@use "../../assets/Fonts/index";
@import "../../assets/Colors/Colors";

.SectionContainer, .SectionContainerAdmin{
    display: flex;
    justify-content: center;

    width: 100%;
    height: 80px;
    background-color: $primary-dark;

    .SectionContent, .SectionAdminContent {
        gap: 10px;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        .TitleSection{
            color: $secondary-light-1;
            font-size: 2.125rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            font-family: "Agne Regular";
            text-align: center;
        }
    }
}

.SectionContainerAdmin{
    height: 100px;
}

@media all and ( max-width: 800px ){
    // mobile view config

    .SectionContainer, .SectionContainerAdmin{
        padding-top: 20px;
        padding-bottom: 20px;

        .SectionContent > .SectionAdminContent > .TitleSection{
            font-size: 1.8rem;
        }

        .SectionAdminContent{
            gap: 10px;
            
            display: flex;
            flex-direction: column;
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }
}