@use '../../assets/Fonts/index.scss';
@import '../../assets/Colors/Colors.scss';

.Container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18px;

    height: 500px;
    background-color: $primary-dark;

    .Content{

        gap: 20px;
        width: 80%;
        height: 100%;
        max-width: 1280px;
        
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .Info{
            width: 50%;
            height: 90%;
            border-radius: 20px;
            font-family: 'Agne Regular';
            background-color: $secondary-light-1;
    
            .InfoTitle{
                display: flex;
                align-items: center;
                justify-content: center;
    
                color: $primary-dark;
                font-size: 35px;
                height: 20%;
            }
    
            .InfoData{
                display: flex;
                flex-direction: column;
                gap: 12px;
                height: calc(80% - 20px);
                padding: 10px 20px;
                overflow-y: hidden;
    
                .InfoDataRow{
                    display: flex;
                    flex-direction: column;
                    gap: 7px;
    
                    .RowTitle{
                        color: $primary-dark;
                        font-size: 18px;
                    }
    
                    .RowText{
                        display: flex;
                        align-items: center;
                        gap: 4px;
    
                        color: $primary-light;
                        font-size: 17px;
                    }
                }
            }
        }
    
        .Map{
            width: 70%;
            height: 90%;
            border-radius: 20px;
            position: relative;
            
            display: flex;
            align-items: flex-end;
            justify-content: center;
            
            background-position: center;
            background-blend-mode: soft-light;
            background-color: rgba(0, 0, 0, 0.226);
            background-image: url("../../assets/LogosPngs/mapSectionImg.png");
            
            a{
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;
    
                width: 230px;
                padding: 0 20px;
                margin-bottom: 10px;
                height: 50px;
                border: none;
                border-radius: 10px;
                background-color: $primary-dark;
                text-decoration: none;
                color: #fff;
    
                span{
                    margin-left: 45px;
                    font-family: 'Hero Regular';
                    font-weight: 600;
                }
            }
        }
    }

}

@media all and (max-width: 1000px){
    .Container{

        .Content{
            
            width: 85%;

            .Info {
                width: 90%;
            }
    
            .Map{
                width: 90%;
            }
        }
    }
}


@media (max-width: 800px) {
    .Container{

        height: 800px;
        padding: 20px 0;
        
        .Content{

            flex-direction: column;
    
            .Info {
                width: 90%;
            }
    
            .Map{
                width: 90%;
            }
        }
    }
}

@media (max-width: 500px) {
    .Container{

        height: 800px;
        padding: 20px 0;
        
        .Content{
            
            flex-direction: column-reverse;
    
            .Info {
                width: 100%;
                height: 90%;
            }
    
            .Map{
                width: 100%;
                height: 90%;
            }
        }
    }
}