.MainComponent {

    .PictureContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        flex-shrink: 0;
        z-index: 1;

        .Picture {
            position: inherit;
            border-radius: inherit;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            background-clip: content-box;
            z-index: 2;
            box-shadow: 0px 20px 15px -3px rgba(0, 0, 0, 0.2);
        }
    }
}