.ModalContainer{

    width: 100%;
    height: 100%;
    z-index: 5000;
    overflow: hidden;
    
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(0, 0, 0, 0.726);
    
    .ModalContent{

        width: 45%;
        flex-shrink: 0;
        max-width: 475px;
        padding: 3rem 3rem;
        
        gap: 35px;
        z-index: 20;
        position: absolute;

        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;

        background: #FFF;
        border-radius: 0.625rem;

        .ModalHeader{

            gap: 10px;
            width: 100%;
            text-align: center;

            font-weight: bold;
            font-weight: 500;
            font-family: system-ui;

            padding: 0.5rem 0.6rem;
            
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;

            :nth-child(2){
                font-weight: 700;
            }
        }

        .ModalButtons{

            width: 85%;
            padding: 3px 0px;

            display: flex;
            align-items: center;
            justify-content: space-between;
            
            .DeleteButton, .CancelButton{

                gap: 0.3rem;
                width: 7.5rem;
                height: 2.5rem;
                padding: 0.6rem;
                
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;

                cursor: pointer;
                border-radius: 5px;

                color: white;
                font-weight: bold;
            }

            .DeleteButton{
                background-color: #C73B38;
            }

            .CancelButton{
                background-color: #54544D;
            }
        }
    }
}

@media all and ( max-width: 1000px ){
    .ModalContainer > .ModalContent{
        gap: 35px;
        width: 45%;

        .ModalHeader{
            gap: 10px;
        }
    }
}


@media all and ( max-width: 800px ){
    .ModalContainer > .ModalContent{
        width: 65%;

        .ModalButtons{
            width: 80%;
        }
    }
}

@media all and ( max-width: 500px ){
    .ModalContainer > .ModalContent{
        width: 70%;

        .ModalHeader{
            font-size: 13px;
        }

        .ModalButtons{
            width: 100%;
        }
    }
}

@media all and ( max-width: 360px ){
    .ModalContainer > .ModalContent{
        width: 80%;
        padding: 3rem 1rem;

        .ModalButtons{
            width: 100%;
        }
    }
}