@import "../../assets/Colors/Colors";

.WhatsLogoContainer{
    right: 20px;
    bottom: 20px;
    
    cursor: pointer;
    position: fixed;
    border-radius: 100px;

    z-index: 1929;

    .WhatsLogoContent{
        padding: 15px;
        display: flex;

        border-radius: 50px;
        align-items: center;
        justify-content: center;
        
        background-color: $primary-whatsapp;
    }
}