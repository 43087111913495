@font-face {
    font-family: "Hero Regular";
    src: url("./Hero\ Regular\ 400.otf");
}

@font-face {
    font-family: "Hero Light";
    src: url("./Hero\ Light\ 200.otf");
}

@font-face {
    font-family: "Hero Bold";
    src: url("./Hero\ Bold\ 700.otf");
}