@use "../../assets/Fonts/index";
@import "../../assets/Colors/Colors";

.ServiceItemContainer{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    width: 23.6875rem;

    .ImageProps{
        
        z-index: 1;
        
        width: 23.6875rem;
        height: 23.9955rem;

        border-radius: 20px;
        object-fit: cover;
        box-sizing: border-box;
        border: 1px solid lightgray;
    }
    
    .ServiceDescribeContainer{
        
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        
        margin-top: -55px;
        background-color: white;
        
        z-index: 2;
        gap: 1.25rem;
        padding: 1.55rem;
        width: 19.875rem;
        border-radius: 20px;

        box-shadow: 0px 5px 10px -5px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 5px 10px -5px rgba(0,0,0,0.75);
        -webkit-box-shadow: 0px 5px 10px -5px rgba(0,0,0,0.75);

        .ServiceHeader{

            display: flex;
            align-items: center;
            justify-content: flex-start;

            width: 100%;
            padding: 0.625rem;
                        
            font-size: 1.05em;
            font-weight: 700;
            font-family: "Cabin";
            color: $primary-dark;
        }

        .DescriptionContainer{
            display: inherit;
            font-weight: 500;

            color: $primary-dark;
            font-family: "Cabin";
        }

        .ButtonContainer{
            width: 100%;
            display: inherit;
            color: $primary-light;

            justify-content: flex-end;

            .ButtonStyle{
                display: flex;
                align-items: center;
                justify-content: center;
                
                border-radius: 1.25rem;
                padding: 0.6875rem 1.75rem;
                background-color: $primary-dark;

                text-decoration: none;
                color: $secondary-light-1;
                font-family: "Cabin";
            }
        }
    }
}

@media all and (max-width: 400px){
    .ServiceItemContainer{
        width: 80%;

        .ImageProps{
            width: 300px;
        }

        .ServiceDescribeContainer{
            width: 90%;
        }
    }
}