@use "../../assets/Fonts/index";
@import "../../assets/Colors/Colors";

*{
    margin: 0px;
    padding: 0px;
}

.NavBarContainerGlobal{

    z-index: 5000;
    
    .NavBarMobContainer, .MenuContainer{
        display: none;
    }

    .NavBarContainer{
        color: white;
        
        .NavBarUpperContainer{
            display: flex;
            justify-content: center;
            background-color: $primary-dark;

            font-family: "Hero Regular";

            .NavBarUpper{
                width: 100%;
                height: 45px;

                display: flex;
                max-width: 1280px;
                padding: 0px 20px;
                justify-content: space-between;
        
                .LocalizacaoNavBar{
                    gap: 5px;
                    display: flex;
                    cursor: pointer;
                    align-items: center;
                    font-size: 0.9rem;

                    a{
                        color: white;
                        text-decoration: none;
                    }

                    a:visited{
                        color: white;
                        text-decoration: none;
                    }

                    .pinLocalSvg{
                        width: 20px;
                        height: 20px;
                    }
                }
        
                .LinksNavBarUpperContainer{
                    display: flex;
                    justify-content: center;
                    // background-color: $secondary-light-1;

                    font-family: "Hero Regular";
                    
                    
                    .CardLinkNavBarContainer:nth-child(1){
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                        background-color: #dbceb8dc;
                    }
                    
                    .CardLinkNavBarContainer{
                        cursor: pointer;
                        text-decoration: none;
                    }
                    
                    .CardLinkNavBar, .LogoutButton{
                        gap: 15px;
                        height: 100%;
                        padding: 0px 20px 0px 20px;

                        display: flex;
                        align-items: center;
                        justify-content: baseline;
                        background-color: $secondary-light-1;

                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                        
                        color: $primary-dark;
                        font-size: 1.1rem;
                        font-family: "Hero Regular";
                    }

                    .LogoutButton{
                        cursor: pointer;
                        color: white;
                        font-weight: bold;
                        border-top-right-radius: 10px;
                        border-top-left-radius: 10px;
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #C02222;
                    }
                }
            }
        }
    
        .NavBarDownContainer{
    
            display: flex;
            justify-content: center;
            background-color: white;
            padding: 10px;
    
            .NavBarDown{
                display: flex;
                align-items: center;
                justify-content: space-between;
                
                width: 100%;
                max-width: 1280px;
    
                color: $primary-dark;
                padding: 0px 50px 0px 50px;
                margin: 0px 20px;
        
                .MainLogo{
                    img{
                        height: 20px !important;
                        min-height: 90px;
                        width: 170px;
                    }
                }
        
                .NavigatorLinksContainer{
        
                    .NavigatorLinksItens{
                        gap: 20px;
        
                        display: flex;
                        align-items: center;
        
                        color: $primary-dark;
                        font-family: "Hero Regular";
                        font-size: 1.1rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
        
                        .NavigatorLinks{
                            list-style-type: none;
                            font-family: "Hero Regular";
                            transition: text-decoration 5s linear;

                            a, button{
                                color: $primary-dark;
                                text-decoration: none;
                            }

                            a:visited{
                                color: $primary-dark;
                                text-decoration: none;
                            }

                            button {
                                font-size: 1.1rem;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                color: $primary-dark;
                                
                                border: none;
                                background: none;
                                font-family: "Hero Regular";
                            }

                            .DropDownContainer{
                                z-index: 100;
                                display: block;
                                position: absolute;
                                width: fit-content;
                                border-bottom-right-radius: 10px;
                                
                                .DropDonwAboutItens{
                                    display: block;
                                    min-width: 100px;
                                    padding: 12px 16px;
                                    margin-bottom: 1px;
                                    border-top-right-radius: 10px;
                                    border-bottom-left-radius: 10px;
                                    background-color: $secondary-light-1;
                                    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
                                }

                                .DropDonwAboutItens:hover{
                                    background-color: $primary-light;
                                }
                            }
                        }
    
                        .NavigatorLinks:hover{
                            cursor: pointer;
                            text-decoration: underline;
                        }
    
                    }
                }
            }
        }
    }
}


@media all and ( max-width: 1050px ){
    // adaptando codigo para telas pequenas
                
    .NavBarContainerGlobal{
        .NavBarContainer{
            .NavBarUpperContainer{
                
                width: 100%;

                .NavBarUpper{
                    width: 100%;
                    height: 40px;
    
                    .LocalizacaoNavBar{
                        font-size: 0.7rem;

                        .pinLocalSvg{
                            width: 15px;
                            height: 15px;
                        }
                    }
                    
                    .LinksNavBarUpperContainer{

                        .CardLinkNavBar, .LogoutButton{
                            padding: 0px 15px 0px 15px;
                        }

                        .CardLinkNavBar{
                            font-size: 0.9rem;
    
                            svg{
                                width: 15px;
                                height: 15px;
                            }
                        }

                        .LogoutButton{
                            font-size: 0.9rem;
                        }
                    }
    
                }
            }
    
            .NavBarDownContainer{
                .NavBarDown{
                    .NavigatorLinksContainer{
                        .NavigatorLinksItens{
                            font-size: 1rem;
                        }
                    }
                }
    
            }
        }
    }
}

@media all and ( max-width: 800px ){
    // mobile view

    .NavBarContainerGlobal{

        .NavBarContainer{
            display: none;
            
            .NavBarUpperContainer{
                display: none;
            }
    
            .NavBarDownContainer{
                display: none;
            }
        }
    
        .NavBarMobContainer{
            display: flex;
            height: 4.8rem;
            padding: 20px 20px 20px 40px;
            background-color: white;
            align-items: center;
            justify-content: space-between;

            img{
                height: 20px;
                min-height: 90px;
                width: 170px;
            }
            
            .MenuCompact{
                display: flex;
                align-items: center;
                justify-content: center;
                
                padding: 5px;
                border: none;
                border-radius: 5px;
                background-color: $primary-dark;
                
                svg{
                    width: 20px;
                    height: 20px;
                    fill: white;
                }
            }

            .MenuClose{
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .MenuContainer{
            
            width: 100%;
            display: flex;
            z-index: 10000;
            position: absolute;
            flex-direction: column;
            background-color: white;
            box-shadow: 1px 0px 1px black;
            
            a{
                text-decoration: none;
                font-family: "Hero Regular";

                .MenuItem, .MenuItemClicked, .ItemLogOut{
                    display: flex;
                    height: 2.5rem;
                    color: $primary-dark;
                    
                    align-items: center;
                    padding: 5px 10px 5px 10px;
                }
            }

            a{
                .MenuItem{
                    color: $primary-dark;
                    background-color: white;
                }
            }

            a{
                background-color: $primary-dark;

                .MenuItemClicked{
                    color: $secondary-light-1;
                    border: 1px solid $primary-dark;
                }
            }

            .ItemLogOut{
                gap: 20px;
                display: flex;
                cursor: pointer;
                align-items: center;
                justify-content: flex-end;
                padding: 5px 10px 5px 10px;
                
                border: none;
                height: 2.7rem;
                padding-right: 20px;
                
                color: white;
                font-weight: bold;
                font-family: "Hero Regular";
                background-color: #c02222ee;

                svg{
                    fill: white;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}