.AboutContainer{
    
    width: 80%;
    max-width: 1280px;
    margin: 3.3rem auto;

    .AboutContent{

        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        gap: 20px;

        .MainAboutClinic{
            
            color: #C69874;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            font-size: 2.1875rem;
            font-family: "Agne Regular";
        }
    
        .TextAboutClinic{
    
            color: #473527;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 400;
            font-family: "Hero Regular";
            line-height: 1.625rem; /* 138.889% */
        }
    }
}

.CarouselContainer{

    margin-top: 3rem;
    margin-bottom: 1.5rem;

    img{
        border-radius: 20px;
    }
}

@media all and (max-width: 1024px){
    .CarouselContainer{

        margin-top: 3rem;
        margin-bottom: 3rem;
    
        img{
            width: 90%;
            height: 80%;
            border-radius: 20px;
        }
    }
}


@media all and (max-width: 800px){
    .AboutContainer{

        .AboutContent{
            
            .MainAboutClinic{
                text-align: center;
                font-size: 1.9rem;
            }

            .TextAboutClinic{
                font-size: 0.9rem;
                text-align: center;
            }
        }
    }

    .CarouselContainer{

        margin-top: 3rem;
        margin-bottom: 3rem;
    
        img{
            width: 250px;
            height: 250px;
            border-radius: 10px;
        }
    }
}