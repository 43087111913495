@use "../../assets/Fonts/index";
@import "../../assets/Colors/Colors";

.MainContainer {
    min-width: 100%;
    min-height: 38rem !important;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 75px;
    
    background-size: cover;
    background-position-y: -20rem;

    .MainTitle {
        font-size: 3em;
        z-index: 30;
        text-align: center;
        font-family: "Hero Regular";
        
        span {
            font-family: "Agne Regular";
        }
    }

    .MainPicture {
        position: absolute;
        opacity: 75%;
        width: 100%;
        min-height: 38rem !important;
    }

    a {
        text-decoration: none;
    }
}

.WhatsappContainer {
    width: 320px;
    height: 50px;
    padding: 3px 0;
    background-color: $primary-whatsapp;
    z-index: 30;
    
    border: none;
    border-radius: 10px;

    text-decoration-style: none;
    font-family: "Hero Light";
    font-weight: 900;
    font-size: 17px;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;

    .Title {
        margin-right: 10px;
    }

    .Logo {
        padding-top: 2.5px;

        img {
            width: 28px !important;
            height: 28px !important;
        }
    }
}

@media (max-width: 800px) {
    .WhatsappContainer {
        display: none; 
    }

    .MainContainer {
        min-height: calc(29rem - 40px);
        min-width: calc(100% - 200px);

        background-position: center;
        background-position-y: 0px;

        .MainTitle {
            font-size: 2.25em;
            margin-top: -200px;
        }

        .MainPicture {
            min-height: 29rem;
        }

        a {
            display: none;
        }
    }
}