.Dots {
    padding: 7.5px;
    cursor: pointer;
    margin-left: 10px;
    border-radius: 10px;
    background-color: #473527;
}

.NaoTemOff{
    height: 10rem;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 300;
    color: #422323;
    text-align: center;
    font-style: normal;
    line-height: normal;
    font-size: 2.1875rem;
    font-family: "Hero Regular";
    background-color: antiquewhite;
}

.DotsInactive {
    padding: 7.5px;
    cursor: pointer;
    margin-left: 10px;
    border-radius: 10px;
    background-color: #473527;
    background-color: lighten(#473527, 45%);
}

.StyleItem {
    
    gap: 20px;
    
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;


    a > span >.imageCarousel, .imagesRenderizadas{
        height: 280px;
        min-height: 255px;
        border-radius: 10px;
        width: 260px;
        object-fit: cover;
        box-sizing: border-box;
    }

    .ButtonsContainer{

        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 100%;
        max-width: 250px;

        .ButtonEdit, .ButtonRemove {
            gap: 0.3rem; 
            cursor: pointer;
            
            width: 6.5rem;
            height: 2.5rem;
            padding: 0.5rem;
            
            display: flex;
            align-items: center;
            justify-content: center;

            border: none;
            color: white;
            font-weight: bold;
            border-radius: 1.25rem;
            
            svg{
                width: 20px;
                height: 20px;
            }
        }
        
        .ButtonEdit{
            background: #54544D;
        }

        .ButtonRemove{
            background: #C73B38;

            svg{
                width: 15px;
                height: 15px;
                stroke-linejoin: round;
            }
        }
    }
}

@media all and (max-width : 1024px) {
    a > .imageCarousel, .imagesRenderizadas{
        width: 220px;
    }

    .StyleItem > .ButtonsContainer{
        max-width: 220px;
    }
}


@media all and ( max-width: 800px ) {
    a > .imageCarousel, .imagesRenderizadas{
        width: 200px;
    }

    .NaoTemOff{
        font-size: 2em;
        font-weight: 300;
        color: #422323;
        text-align: center;
        background-color: antiquewhite;
    }

    .StyleItem > .ButtonsContainer{
        max-width: 200px;

        .ButtonEdit, .ButtonRemove {
            gap: 0.2rem;
            width: 5rem;

            svg{
                width: 15px;
                height: 15px;
            }
        }
    }
}

@media all and ( max-width: 620px ){
    a > .imageCarousel, .imagesRenderizadas{
        width: 180px;
    }

    .StyleItem > .ButtonsContainer{
        max-width: 180px;

        .ButtonEdit, .ButtonRemove {
            width: 5rem;

            svg{
                width: 15px;
                height: 15px;
            }
        }
    }
}

@media all and ( max-width: 568px ){
    a > .imageCarousel, .imagesRenderizadas{
        width: 220px;
    }

    .StyleItem > .ButtonsContainer{
        max-width: 220px;

        .ButtonEdit, .ButtonRemove {
            width: 6.5rem;

            svg{
                width: 15px;
                height: 15px;
            }
        }
    }
}