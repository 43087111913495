.ModalContainer{
    
    z-index: 5000;
    position: fixed;

    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    .DarkSide{
        height: 100%;
        width: 100%;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.568);
    }

    .ModalContentContainer{
        
        height: 100%;
        
        gap: 15px;
        padding-top: 5rem;
        position: relative;
        
        display: flex;
        align-items: center;
        flex-direction: column;
        
        font-family: "Agnes";
        background-color: #68462B;
        
        .CloseButton{
            position: absolute;

            display: flex;
            align-items: center;
            justify-content: center;

            left: 0;
            padding: 10px;
            border-radius: 10px;
            margin-top: -20px;
            margin-left: 20px;

            background-color: #462d03;
            border: 1px solid #68462B;

        }

        .ModalHeader{

            font-size: 40px;
            font-weight: 500;
            font-style: normal;
            text-align: center;
            line-height: normal;
            font-size: 2.1875rem;

            color: #F8E8CF;
            font-family: "Hero Regular";
        }

        .ModalContent{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            gap: 30px;
            width: 70%;
            padding: 40px;
            padding-bottom: 60px;

            border-radius: 1.5rem;
            background: #F8E8CF;

            .ModalContentFirstColumn{
                width: 50%;
                height: 100%;

                gap: 1.25rem;

                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

                .MainHeader{
                    color: #422323;
                    text-align: center;
                    font-size: 2.1875rem;
                    font-style: normal;
                    font-weight: 400;
                    font-family: "Hero Regular";
                }

                .Header{
                    color: #68462B;
                    font-size: 0.9rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }

                .ImageContainer{
                    
                    min-width: 200px;
                    min-height: 250px;

                    max-width: 270px;
                    max-height: 270px;

                    border-radius: 10px;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                    svg{
                        filter: invert(100%) sepia(100%) saturate(1000%) hue-rotate(100deg) brightness(100%) contrast(70%);
                    }

                    &:hover{
                        opacity: 0.6;
                    }

                }

                .ImageContainer:hover >.ImageBackground{
                    opacity: 0.2;
                    background-color: #F8E8CF;
                }
            }

            .ModalContentSecondColumn{
                width: 50%;
                height: 100%;

                gap: 20px;
                display: flex;
                flex-direction: column;

                .MainHeader{
                    font-weight: 300;
                    color: #422323;
                    text-align: center;
                    font-style: normal;
                    line-height: normal;
                    font-size: 2.1875rem;
                    font-family: "Hero Regular";
                }

                .InputSection{
                    width: 100%;
                    gap: 30px;
                    display: flex;
                    flex-direction: column;

                    .InputElement{

                        .LabelInput{
                            font-size: 1rem;
                            font-weight: 700;
                            color: #422323;
                            text-align: center;
                            font-style: normal;
                            line-height: normal;
                            font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                        }

                        input{
                            height: 2rem;
                            width: 100%;
                            border: none;
                            outline: none;
                            background: none;
                            background-color: none;
                            border-bottom: 2px solid #422323;
                        }
                    }
                    .InputElementDrop{
                        
                        gap: 20px;
                        display: flex;
                        flex-direction: column;

                        input[type="file"]{
                            display: none;
                        }

                        .LabelInput{
                            font-size: 1rem;
                            font-weight: 700;
                            color: #422323;
                            font-style: normal;
                            line-height: normal;
                            font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                        }
                        
                        .SelectImageButton{
                            display: flex;

                            height: 5rem;
                            width: 100%;
                            cursor: pointer;
                            border-radius: 10px;
                            border: 2px solid #68462B;

                            display: flex;
                            align-items: center;
                            flex-direction: column;
                            justify-content: center;
                        }
                        
                        .LabelSendInput{
                            font-size: 1rem;
                            font-weight: 500;
                            color: #422323;
                            text-align: center;
                            font-style: normal;
                            line-height: normal;
                            font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                        }
                    }
                }
            }

        }
        
        button{
            width: 60%;

            border: none;
            height: 3rem;
            cursor: pointer;
            border-radius: 10px;
            background-color: #F8E8CF;

            font-size: 15px;
            font-weight: 700;
            color: #422323;
            font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        }

        button:disabled{
            cursor: not-allowed;
            background-color: #F8E8CF;
        }

        button:disabled:hover{
            background-color: #F8E8CF;
        }

        button:hover{
            background-color: #f8e8cf8e;
        }
    }
}

@media all and (max-width : 800px){

    .ModalContainer > .ModalContentContainer{
        width: 100%;
        overflow-y: auto;

        .ModalContent{
            width: 80%;
        }
    }
}

@media all and (max-width: 600px){
    
    .ModalContainer{
        min-height: 100vh;
        overflow-y: auto;

        .DarkSide{
            display: none;
        }

        .ModalContentContainer{

            display: grid;
            align-items: stretch;
            justify-items: center;

            .CloseButton{
                margin-top: 85px;
            }

            .ModalContent{
                display: flex;
                flex-direction: column;
                padding: 20px;
            
                .ModalContentFirstColumn{
                    order: 2;
                    width: 100%;
                }

                .ModalContentSecondColumn{
                    order: 0;
                    width: 100%;
                }
            }
        }
    }
}

@media all and (max-width: 375px){

    .ModalContainer > .ModalContentContainer > .SubmitButton{
        width: 90%;
    }
}