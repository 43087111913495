@use '../../assets/Fonts/index';
@import '../../assets/Colors/Colors';

.Container {
    display: flex;
    justify-content: center;
    position: relative;
    font-family: "Hero Regular";
    width: fit-content;

    .PictureContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 7rem;
        height: 7rem;
        border-radius: 6.5rem;
        flex-shrink: 0;
        background: linear-gradient(to left, $primary-dark, $primary-light);
        z-index: 1;
        
        .Picture {
            position: inherit;
            width: 95%;
            height: 95%;
            border-radius: inherit;
            background-size: cover;
            background-repeat: no-repeat;
            background-clip: content-box;
            z-index: 2;
        }
    }

    .Square {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;

        z-index: 0;

        position: relative;
        text-align: center;
        background-color: $secondary-light-2;
        height: 15rem;
        width: 16rem;
        border-radius: 12px;
        box-shadow: 0 0.3rem 0.6rem #d4d4d4;

        .Text {
            font-family: "Hero Regular";
            padding-top: 2.5rem;

            .Title {
                padding-top: 3px;
            }
        }

        .AboutButton {
            padding-top: 2rem;

            button {
                font-family: "Hero Regular";
                border: none;
                background-color: $primary-light;
                width: 9rem;
                height: 3rem;
                border-radius: 25.5px;
                cursor: pointer;
            }
        }
    }
}