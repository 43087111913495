@use '../../assets/Fonts/index';

.PaginationContainer{
    width: 85%;
    margin: auto;
    max-width: 1280px;
    padding-top: 35px;
    padding-bottom: 25px;
    
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    align-items: flex-start;
    justify-content: space-evenly;

    .ButtonContainer{
        display: flex;
        justify-content: center;

        width: 100%;
        grid-column: 2;
        font-family: "Hero Regular";

        .Button{
            display: flex;
            align-items: center;
            justify-content: center;
    
            width: 9.6875rem;
            height: 3rem;
    
            background: #F7E7CE;
            border-radius: 3.125rem;

            cursor: pointer;
    
            border: none;
            color: #473527;
            font-style: normal;
            line-height: normal;
            font-size: 1.0625rem;
            font-weight: lighter;
            text-decoration: none;
            font-family: "Hero Regular";
        }
    }
}

@media all and (max-width: 400px){
    .PaginationContainer{
        width: 100%;
    }
}